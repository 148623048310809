import { createStore } from 'vuex'
import {
  extractUrlParams,
  getIpAddress,
  getJornayaLeadIdToken,
  getTrustedFormByPromise,
  goToDisqualify,
  goToThankYouPage,
  postData,
  setEverflow,
  saveUserProgressionAndBackup
} from "@/js/utils";
import { getSSDIQualified } from '@/js/qualified'
import roundup from "@/store/flows/roundup"
import workersComp from "@/store/flows/workersComp"
import twilioSmsVerify from '@/store/twilio-sms-verify'
import workersCompV2 from '@/store/flows/workersCompV2'
import stormDamage from '@/store/flows/stormDamage'
import mva from '@/store/flows/mva'
import dui from '@/store/flows/dui'
import workersCompSMSVerify from '@/store/flows/workersCompSMSVerify'
import workersCompV3 from '@/store/flows/workersCompV3'
import campLejeuneSettlement from '@/store/flows/campLejeuneSettlement'
import workersCompMulti from '@/store/flows/workersCompMulti'
import router from '@/router'
import ssdi from '@/store/flows/ssdi'
import preSettlementFunding from '@/store/flows/pre-settlement-funding'

const urlParams = extractUrlParams()
let affid = '1'
let sub_id = ''
if (urlParams.affid) {
  affid = urlParams.affid
}
if (urlParams.sub_id) {
  sub_id = urlParams.sub_id
}

export default createStore({
  modules: {
    roundup,
    workersComp,
    twilioSmsVerify,
    workersCompV2,
    stormDamage,
    mva,
    dui,
    workersCompSMSVerify,
    workersCompV3,
    campLejeuneSettlement,
    workersCompMulti,
    ssdi,
    preSettlementFunding
  },
  state: {
    steps: {},
    fields: {
      campaignId: 0,
      supplierId: 0,
      key: ''
    },
    tortData: {},
    DQ: {
      isDisqualified: false,
      reason: '',
      affid: affid,
      sub_id: sub_id
    },
    page: '',
    progress: 0,
    isLoading: false,
    currentStep: null,
    previousStep: null,
    previousProgress: 0,
    nextStep: null,
    minHeightOfTheLastComponent: 0,
    phoneError: false,
    sessionId: null,
    tmpUserData: [],
  },
  getters: {
    stepsKeys(state) {
      return Object.keys(state.steps)
    },
    getTortData(state) {
      return state.tortData
    },
    getFields(state) {
      return state.fields
    },
    getDQInfo(state) {
      return state.DQ
    }
  },
  mutations: {
    SET_SESSION_ID(state, sessionId) {
      state.sessionId = sessionId
    },
    SET_EF_OID(state, oid) {
      //check in url parameters if there oid is different from the default oid
      const urlParams = new URLSearchParams(location.search)
      let everflow_offer_id = oid

      if (urlParams.has('oid') && (urlParams.get('oid') !== oid)) {
        everflow_offer_id = urlParams.get('oid')
      }

      state.fields.oid = everflow_offer_id
    },

    SET_IS_LOADING(state, status) {
      state.isLoading = status
    },

    SET_CURRENT_STEP( state, step ) {
      state.currentStep = step
    },

    SET_PREVIOUS_STEP( state, previousStep ) {
      state.previousStep = previousStep
    },

    SET_PREVIOUS_PROGRESS(state, previousProgress) {
      state.previousProgress = previousProgress
    },

    SET_CURRENT_PROGRESS(state, progress) {
      state.progress = progress
    },

    SET_FIELD(state, { field, value }) {
      state.fields[field] = value
    },

    SET_ARRAY_OF_FIELDS(state, array) {
      array.forEach(object => {
        state.fields[object.field] = object.value
      })
    },

    SET_TORT_DATA(state, { field, value }) {
      state.tortData[field] = value
    },

    SET_ARRAY_OF_TORT_DATA(state, array) {
      array.forEach(object => {
        state.tortData[object.field] = object.value
      })
    },

    SET_CAMPAIGN(state, { campaignId, supplierId, key }) {
      state.fields.campaignId = campaignId
      state.fields.supplierId = supplierId
      state.fields.key = key
    },

    SET_DISQUALIFIED(state, { reason }) {
      state.DQ.isDisqualified = true
      state.DQ.reason = reason
    },

    SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT(state, value) {
      state.minHeightOfTheLastComponent = value
    },

    SET_PHONE_ERROR(state, value) {
      state.phoneError = value
    },
    SET_OBJECT_OF_TORT_FIELDS(state, object) {
      for (const property in object) {
        state.tortData[property] = object[property]
      }
    }
  },
  actions: {
    init({commit, dispatch}) {
      //set everflow
      setEverflow().then(({ef_transaction_id}) => {
        commit('SET_FIELD', { field: 'ef_transaction_id', value: ef_transaction_id})
      })

      // get ip
      getIpAddress().then((ip) => {
        commit('SET_FIELD', { field: 'ipAddress', value: ip })
      })

      // get jornaya lead id token
      getJornayaLeadIdToken().then(jornayaId => {
        commit('SET_FIELD', { field: 'jornayaId', value: jornayaId })
      })

      // get trusted form

      getTrustedFormByPromise().then(({ trustedFormId, trustedFormUrl }) => {
        const trustedForm = [
          {field: 'trustedFormId', value: trustedFormId},
          {field: 'trustedFormUrl', value: trustedFormUrl}
        ]
        dispatch('setAnswers', trustedForm)
      })
    },

    setAnswers({ commit }, answers) {
      answers.forEach(answer => {
        commit('SET_FIELD', {
          field: answer.field,
          value: answer.value,
        })
      })
    },

    setTortData({commit}, tortData) {
      tortData.forEach(tort => {
        commit('SET_TORT_DATA', {
          field: tort.field,
          value: tort.value
        })
      })
    },

    goToDisqualifyPage({state}) {
      goToDisqualify(state.DQ.affid, state.DQ.sub_id, state.DQ.reason)
    },

    goToQualifyPage({state}) {
      goToThankYouPage(affid, sub_id, state.page, state.fields.sub_id1)
    },

    async postData({state, dispatch}) {
      await postData(state.fields, state.tortData, state.sessionId)
      if (state.tortData.offer === 'aat' && (window.location.pathname === '/wc-multi' || window.location.pathname === '/wc-multi/')) {
        window.location.href = '/thank-you-mva?'
          + `_ef_transaction_id=${state.fields.ef_transaction_id}`
          + `&sub1=${state.fields.sub_id || ''}`
          + `&affid=${state.fields.affid || ''}`
          + `&q=${state.DQ.isDisqualified ? 'n' : 'y'}`
          + `&page=${window.location.pathname.replace('/', '')}`
        return
      }

      // if (state.DQ.isDisqualified) {
      //   //dispatch to go to disqualify page
      //   dispatch('goToDisqualifyPage')
      //   return
      // }

      if (state.tortData.offer === 'cl' && (window.location.pathname === '/wc-multi' || window.location.pathname === '/wc-multi/')) {
        router.push({
          path: '/thank-you-cj',
          query: {
            _ef_transaction_id: state.fields.ef_transaction_id,
            affid: state.fields.affid,
            sub_id: state.fields.sub_id,
            q: 'y'
          }
        })
        return
      }

      if (window.location.pathname === '/dui' || window.location.pathname === '/dui/') {
        router.push({
          path: '/thank-you-dui',
          query: {
            _ef_transaction_id: state.fields.ef_transaction_id,
            affid: state.fields.affid,
            sub_id: state.fields.sub_id,
            q: state.tortData.pending_dui_charges === 'yes' ? 'y' : 'n'
          }
        })
        return
      }

      if (window.location.pathname === '/ssdi-flow' || window.location.pathname === '/ssdi-flow/') {
        router.push({
          path: '/thank-you-ssdi',
          query: {
            _ef_transaction_id: state.fields.ef_transaction_id,
            affid: state.fields.affid,
            sub_id: state.fields.sub_id,
            q: state.fields.qualified === 'yes' ? 'y' : 'n'
          }
        })
        return
      }

      //dispatch to go to qualify page
      dispatch('goToQualifyPage')
    },

    async saveUserProgressionAndBackup({state}, {userProgression, userBackup}) {
      state.tmpUserData.push({
        userProgression,
        userBackup
      })

      if (!state.sessionId) {
        return
      }

      try {
        const response = await saveUserProgressionAndBackup({
          session: state.sessionId,
          data: state.tmpUserData
        })

        if (response.userBackupStatus && response.userProgressionStatus) {
          state.tmpUserData = []
        }
      } catch(e) {}
    },
  }
})
