export const StringUtils = {
  formatDateOfBirth(date) {
    if (!date) return '';
    const getSplitIdentifier = date.indexOf('-') > -1 ? '-' : '/';
    const parts = date.split(getSplitIdentifier);
    if (parts.length === 3 && parts[2].length === 4) {
      // It's in DD-MM-YYYY format, rearrange to YYYY-MM-DD
      console.log('should be returned', `${parts[2]}-${parts[1]}-${parts[0]}`)
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    // Return the original date if it doesn't match expected formats
    return date;
  }
}